<template>
  <a-modal
    :key="selectKey"
    class="modifiers-edit-modal"
    title="Редактировать продукт"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="modifier">
      <div v-if="fetching" class="spin-loader">
        <a-spin/>
      </div>
      <div v-else class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <a-upload
              name="image_url"
              list-type="picture-card"
              class="photo-uploader"
              :show-upload-list="false"
              :headers="getHeaders()"
              :action="getUrl"
              :custom-request="customRequest"
              :before-upload="uploadValidation"
              @change="uploadImage"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
              <div v-else>
                <LoadingOutlined v-if="loading" />
                <PlusOutlined v-else />
                <div class="ant-upload-text">Фото</div>
              </div>
            </a-upload>
            <div class="mb-4 text-center" v-if="imageUrl">
              <a-button @click="removeImage" type="link" danger>
                <CloseCircleOutlined/>
                Удалить фотографию
              </a-button>
            </div>
          </div>
          <div class="col-md-12">
            <a-form-item label="Название">
              <a-input
                ref="modifierName"
                placeholder="Введите название"
                v-model:value="modifier.name"
              />
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Группа">
              <a-select
                placeholder="Выберите группу"
                v-model:value="modifier.group_id"
                :options="groups"
              ></a-select>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Цена">
              <a-input
                placeholder="Введите цену"
                v-model:value="modifier.price"
              />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, watch, toRefs, nextTick} from 'vue';
import {
  CloseCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import {getHeaders} from "@/helpers/header";
import {getBase64} from "@/helpers/base64";
import apiClient from "@/services/axios";
import Modifier from "@/models/Modifier";
import {message} from "ant-design-vue";
import axios from "axios";

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  modifierId: {
    type: Number,
    default: 0,
  },
  modal: {
    type: Boolean,
    default: false,
  },
  groups: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let
  isShowModal = ref(false),
  imageUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  fetching = ref(true),
  modalVisible = ref(false),
  modifierName = ref("modifierName"),
  initialModifier = new Modifier({}),
  modifier = reactive(initialModifier),
  selectKey = ref(0),
  spic = ref(null),
  spicData = ref([]),
  spicValue = ref([]),
  spicFetching = ref(false),
  code = ref(null),
  codeData = ref([]),
  codeValue = ref([]),
  codeFetching = ref(false);

const stores = ref([]);
const state = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: [],
});

toRefs(state);

const
  resetForm = () => {
    Object.assign(modifier, new Modifier({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(modifier);

    return apiClient.put(`/modifier/update?modifier=${props.modifierId}`, model).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
        isShowModal.value = true
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    modifier.image_url = ''

    confirmLoading.value = true;
    apiClient
      .put(`/modifier/update?modifier=${props.modifierId}`, {image_url: '', big_image_url: ''})
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  customRequest = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: getHeaders(),
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("image_url", file);
    try {
      const res = await axios.post(
        getUrl(),
        fmData,
        config,
      );

      onSuccess("Ok");
      // const data = res.data.data
    } catch (err) {
      // console.log("Error: ", err);
      onError({ err });
    }
  },
  uploadValidation = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/modifier/upload-image'
  },
  onCheckAllChange = (e) => {
    Object.assign(state, {
      checkedList: e.target.checked ? stores.value.map(key => key.value) : [],
      indeterminate: false,
    });
  },
  getModifier = () => {
    apiClient.get(`/modifier/view?id=${props.modifierId}`).then(response => {
      if (response.data) {
        stores.value = response.data.data.stores;
        modalVisible.value = true;
        nextTick(() => {
          if (modifierName.value !== 'modifierName')
            modifierName.value?.focus()
        });
        let model = new Modifier(response.data.data)
        Object.assign(modifier, model);
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false;
      fetching.value = false;
    })
  }

watch(spicValue.value, () => {
  spicData.value = [];
  spicFetching.value = false;
});

watch(codeValue.value, () => {
  codeData.value = [];
  codeFetching.value = false;
});

watch(
  () => state.checkedList,
  val => {
    state.indeterminate = !!val.length && val.length < stores.value.length;
    state.checkAll = val.length === stores.value.length;
  },
);

watch(
  () => props.modal,
  async val => {
    if (val) {
      await getModifier();
    }
  },
)

</script>

<style scoped>
@import "modifiers.css";
</style>

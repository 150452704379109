<template>
  <a-modal
    :key="selectKey"
    class="modifiers-edit-modal"
    title="Редактирование продуктов"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="modifier">
      <div v-if="fetching" class="spin-loader">
        <a-spin/>
      </div>
      <div v-else class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <a-upload
              name="image_url"
              list-type="picture-card"
              class="photo-uploader"
              :show-upload-list="false"
              :headers="getHeaders()"
              :action="getUrl"
              :before-upload="uploadValidation"
              @change="uploadImage"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar"/>
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <PlusOutlined v-else></PlusOutlined>
                <div class="ant-upload-text">Фото</div>
              </div>
            </a-upload>
            <div class="mb-4 text-center" v-if="imageUrl">
              <a-button @click="removeImage" type="link" danger>
                <CloseCircleOutlined/>
                Удалить фотографию
              </a-button>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Категория">
                  <div v-if="editGroup" class="row">
                    <div class="col-md-10">
                      <a-select
                        placeholder="Выберите группу"
                        v-model:value="modifier.group_id"
                        :options="groups"
                      ></a-select>
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editGroup = false" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <CloseCircleOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-md-10">
                      <a-input disabled placeholder="Без изменений" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editGroup = true" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <EditOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Цена">
                  <div v-if="editPrice" class="row">
                    <div class="col-md-10">
                      <a-input
                        placeholder="Введите цену"
                        v-model:value="modifier.price"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editPrice = false" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <CloseCircleOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-md-10">
                      <a-input disabled placeholder="Без изменений" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="editPrice = true" type="text" shape="round" class="px-2 pull-right">
                        <template #icon>
                          <EditOutlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, watch, toRefs} from 'vue';
import {CloseCircleOutlined, EditOutlined } from "@ant-design/icons-vue";
import {getHeaders} from "@/helpers/header";
import {getBase64} from "@/helpers/base64";
import apiClient from "@/services/axios";
import Modifier from "@/models/Modifier";
import {message} from "ant-design-vue";

const props = defineProps({
  modifierIds: {
    type: Array,
    default: null,
  },
  modal: {
    type: Boolean,
    default: false,
  },
  groups: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let imageUrl = ref(''),
  loading = ref(true),
  confirmLoading = ref(false),
  fetching = ref(true),
  modalVisible = ref(false),
  initialModifier = new Modifier({}),
  modifier = reactive(initialModifier),
  selectKey = ref(0),
  spic = ref(null),
  spicData = ref([]),
  spicValue = ref([]),
  spicFetching = ref(false),
  code = ref(null),
  codeData = ref([]),
  codeValue = ref([]),
  codeFetching = ref(false),
  editGroup = ref(false),
  editDescription = ref(false),
  editPrice = ref(false),
  editSpic = ref(false);

const stores = ref([]);
const state = reactive({
  indeterminate: true,
  checkAll: false,
  checkedList: [],
});

toRefs(state);

const
  resetForm = () => {
    Object.assign(modifier, new Modifier({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(modifier);
    delete model['name'];
    if (!modifier['image_url'].length)
      delete model['image_url']
    if (!editGroup.value)
      delete model['group_id']
    if (!editPrice.value)
      delete model['price']

    return apiClient.put(`/modifier/update`, {
      modifiers: props.modifierIds,
      ...model,
    }).then(response => {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      modifier.image_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  removeImage = () => {
    imageUrl.value = ''
    modifier.image_url = ''

    confirmLoading.value = true;
    apiClient
      .put(`/modifier/multiple-update?modifiers=${props.modifierIds}`, {image_url: ''})
      .then(response => {
        if (response) {
          confirmLoading.value = false;
        }
      }).catch(err => {
      confirmLoading.value = false;
    }).finally(() => {
      confirmLoading.value = false;
    })
  },
  uploadValidation = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/modifier/upload-image'
  },
  onCheckAllChange = (e) => {
    Object.assign(state, {
      checkedList: e.target.checked ? stores.value.map(key => key.value) : [],
      indeterminate: false,
    });
  }
  /*
  getProduct = () => {
    apiClient.get(`/menu/product-view?uuid=${props.modifierIds}`)
      .then(response => {
        if (response.data) {
          stores.value = response.data.data.stores;
          modalVisible.value = true;
          nextTick(() => productName.value?.focus());
          let model = new Product(response.data.data)
          Object.assign(product, model);
          imageUrl.value = product.image_url;
          if (Object.keys(model.spicModel).length) {
            fetchInitialData(model)
          } else {
            spicData.value = [];
            spic.value = [];
          }
          state.checkedList = response.data.data['selected_stores'];
        }
      }).catch(err => {
      console.log(err)
      loading.value = false;
    })
      .finally(() => {
        loading.value = false;
        fetching.value = false;
      })
  }
*/

watch(spicValue.value, () => {
  spicData.value = [];
  spicFetching.value = false;
});

watch(codeValue.value, () => {
  codeData.value = [];
  codeFetching.value = false;
});

watch(
  () => state.checkedList,
  val => {
    state.indeterminate = !!val.length && val.length < stores.value.length;
    state.checkAll = val.length === stores.value.length;
  },
);

watch(
  () => props.modal,
  async val => {
    if (val) {
      modalVisible.value = true;
      loading.value = false;
      fetching.value = false;
    }
  },
)

</script>

<style scoped>
@import "modifiers.css";
</style>
